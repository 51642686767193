import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import PostActionsSaved from "./PostActionsSaved"
import Snackbar from "./SnackBar";
import ScrollHide from "./ScrollHide";


class Saved extends Component {

componentWillMount(){
    this.props.CatuxySpaceStore.getSavedPosts();
}

showSnackBar(){
  if(this.props.CatuxySpaceStore.showSnackBarState === true){
    return <Snackbar message="Link copied to clipboard"/>
  }else{
    return ""
  }
}


constructUrl = (date) => {
    let dateArray = date.split("-");
    let Year = dateArray[0];
    let Month = dateArray[1];
    let Day = dateArray[2];
    return `https://space.catuxy.com/apod/${Year}/${Month}/${Day}`
}

defineGraphic_element(posturl,posttitle){
    if(posturl.indexOf("png") > -1 || posturl.indexOf("jpg") > -1 || posturl.indexOf("jpeg") > -1 || posturl.indexOf("gif") > -1 ){
          return  <img src={posturl} alt={posttitle} className="story_img" />;
    }else if(posturl.indexOf("youtube") > -1){
          return <iframe width="100%" height="315" src={posturl} title={posttitle} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }else if(posturl.indexOf("vimeo") > -1){
          return <iframe src={posturl} title={posttitle} width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
    }else{
        return <img src={posturl} alt={posttitle} className="story-img" />;
    }
}
    


  render() {

    const { CatuxySpaceStore } = this.props;

    return (
      <div>
        <TopNav tabname="Saved in device" />
        <div className="add_transition">
        <div className="feed-c">
          {CatuxySpaceStore.savedPosts.map(post => (
            <div
              key={post.title + Math.random()}
              id={post.title + Math.random()}
              className="story"
            >
              <a href={post.hdurl} className="story_hdlink" target="_blank" rel="noopener noreferrer">
                {this.defineGraphic_element(post.url,post.title)}
              </a>
              <div className="story_title">{post.title}</div>
              <div className="story_date">{post.date}</div>
              <div className="story_explanation">{post.explanation}</div>
              <PostActionsSaved identificator={post.id} shareUrl={this.constructUrl(post.date)}/>
              </div>
          ))}
        </div>
        </div>
        {this.showSnackBar()}
        <ScrollHide />
        <BottomNav />
      </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Saved));