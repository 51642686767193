import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "mobx-react";
import CatuxySpaceStore from "./stores/CatuxySpaceStore";
import { BrowserRouter } from "react-router-dom";

const Root = (
  <BrowserRouter>
    <Provider CatuxySpaceStore={CatuxySpaceStore}>
      <App />
    </Provider>
  </BrowserRouter>
);
ReactDOM.render(Root, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
