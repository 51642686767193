import React, { Component } from "react";
import { inject, observer } from "mobx-react";


class PostActionsSaved extends Component {



  clickCopy(str){
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.props.CatuxySpaceStore.enableSnackBar();
  }
  
  clickDelete(id) {
    var _this = this;
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("CatuxySpaceDatabase", 1);
  
    open.onsuccess = function() {
    
        var db = open.result;
        var tx = db.transaction("SavedPostsStore", "readwrite");
        var store = tx.objectStore("SavedPostsStore");
        var index = store.index("DateIndex");
    
        var checkIfSaved = index.get([id]);
  
        checkIfSaved.onsuccess = function(){
             //Remove saved item
             store.delete(id);
             _this.props.CatuxySpaceStore.getSavedPosts();
        }
        tx.oncomplete = function() {
            db.close();
        };
      }
}
  




  render() {
    return (
      <div className="story-actions">
      <button className="s_actions_btn right delete" id={this.props.identificator} onClick={() =>{this.clickDelete(this.props.identificator)}}></button>
      <button className="s_actions_btn left share" id={this.props.identificator+"share"} onClick={() =>{this.clickCopy(this.props.shareUrl)}}></button>
      </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(PostActionsSaved));