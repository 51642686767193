import React, { Component } from "react";
import { inject, observer } from "mobx-react";

class Rovers extends Component {

    constructor(props) {
        super(props);
        this.state = {
        imagesArray:[],
        sol:  this.props.sol,
        imageNum:0,
        currentImage:"",
        galleryState:"none",
        currentPosition:0,
        currentPage:0,
        isFetching: false,
        isTheEndReached:false
        }
        this.setGalleryState = this.setGalleryState.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.nextPhoto = this.nextPhoto.bind(this);
        this.previousPhoto = this.previousPhoto.bind(this);
        this.infiniteScroll = this.infiniteScroll.bind(this);
        this.nextSol = this.nextSol.bind(this);
        this.previousSol = this.previousSol.bind(this);
      }

componentWillMount(){
var _this = this;
_this.getPhotos();
document.addEventListener("scroll", _this.infiniteScroll);
window.addEventListener('keydown', function(event) {
if(_this.state.galleryState === "block"){
  switch (event.key) {
    case "ArrowLeft":
        _this.previousPhoto();
        break;
    case "ArrowRight":
        _this.nextPhoto();
        break;
    default:
}
}else{

}


});
}

componentDidMount(){
  document.getElementById("sol_input").value = this.props.sol;
}

componentWillUnmount(){
  document.removeEventListener("scroll", this.infiniteScroll);
}

componentWillReceiveProps(){
  this.setState({
    imagesArray:[],
    sol:  this.props.sol,
    imageNum:0,
    currentImage:"",
    galleryState:"none",
    currentPosition:0,
    currentPage:0,
    isFetching: false,
    isTheEndReached:false
  },
  () => {this.getPhotos()},
  () => {document.getElementById("sol_input").value = this.props.sol}
  );

}

componentDidUpdate(){
  document.getElementById("sol_input").value = this.props.sol;
}



nextSol(){
  var shit = parseInt(this.props.sol) + 1;
  var stringShit = shit.toString();
  this.props.routerData.history.push(stringShit);
}

previousSol(){
  var shit = parseInt(this.props.sol) - 1;
  var stringShit = shit.toString();
  this.props.routerData.history.push(stringShit);
}


setGalleryState(state){
  var _this = this;
  if(state === true){
    _this.setState({galleryState:"block"});
  }else{
    _this.setState({galleryState:"none"});
  }
}

infiniteScroll(){
  var _this = this;
  var scrollPosition = window.pageYOffset;
  var windowSize = window.innerHeight;
  var bodyHeight = document.body.offsetHeight;
  if(_this.state.isTheEndReached === false &&_this.state.isFetching === false && Math.max(bodyHeight - (scrollPosition + windowSize), 0) <= 1500){
    //console.log(Math.max(bodyHeight - (scrollPosition + windowSize)));
    _this.setState(
      {currentPage: _this.state.currentPage + 1},
      () => {_this.getPhotos()});
  }else{
    //console.log(Math.max(bodyHeight - (scrollPosition + windowSize)));
  }
}

openGallery(id){
  var position = this.state.imagesArray.findIndex(obj => obj.id===id);
  this.setState({currentPosition: position});
  this.setState({currentImage: this.state.imagesArray[position].img_src.replace("http://", "https://")});
  this.setGalleryState(true);
}




getPhotos(){
    var _this = this;
    _this.setState({isFetching: true});
    var promise1 = new Promise(function (resolve, reject) {
    fetch(
      `https://api.nasa.gov/mars-photos/api/v1/rovers/${_this.props.name}/photos?sol=${_this.props.sol}&page=${_this.state.currentPage}&api_key=BbBKQrv5jnrqm2qJ2m9Ba61P9kAPPFYTfDX76o0T`
    )
    .then(function (response) {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log(response.status);
        reject("Promise rejected")
      }
    }).then(function(jsonresponse){
      console.log(JSON.stringify(jsonresponse));
      if(jsonresponse.photos.length === 0){
        _this.setState({
          isTheEndReached: true
        })
        resolve("shit");
        return jsonresponse;
      }else{
        var temporalArray = [];
        temporalArray.push.apply(temporalArray, _this.state.imagesArray)
        temporalArray.push.apply(temporalArray, jsonresponse.photos)
        _this.setState({
          imagesArray: temporalArray
        }, () => {_this.setState({isFetching: false});});
        resolve("shit");
        return jsonresponse;
      }

    })
    .catch(function (error) {
      console.log("fetch_error:", error);
      _this.setState({isFetching: false});
      reject("Promise rejected")
    });
  });
  return promise1;
  }


  nextPhoto(){
    if(this.state.currentPosition >= this.state.imagesArray.length - 2){

    }else{
      this.setState(
        {currentPosition: this.state.currentPosition + 1},
        () => {this.setState({currentImage: this.state.imagesArray[this.state.currentPosition].img_src})}
        );
    }
  }

  previousPhoto(){
    if(this.state.currentPosition <= 0){

    }else{
      this.setState(
        {currentPosition: this.state.currentPosition - 1},
        () => {this.setState({currentImage: this.state.imagesArray[this.state.currentPosition].img_src})}
        );
    }
  }

  changeSol () {
    var _this = this;
    var sol = document.getElementById("sol_input").value;
    _this.props.routerData.history.push(sol);
  }

  render() {
    return (
        <div className="rovers">
        
        <div className="gallery" style={{display:this.state.galleryState}}>
        <button className="gallery_top_btn close"  onClick={() => {this.setGalleryState(false)}}></button>
        <a href={this.state.currentImage} target="_blank" rel="noopener noreferrer">
        <button className="gallery_top_btn open"></button>
        </a>
        <img src={this.state.currentImage}  alt="" className="gallery_img"/>
        <button className="gallery_btn previous" onClick={() => {this.previousPhoto()}}></button>
        <button className="gallery_btn next" onClick={() => {this.nextPhoto()}}></button>
        <div className="gallery_click_close" onClick={() => {this.setGalleryState(false)}}></div>
        </div>


        {this.state.imagesArray.map(image => (
            <div
              key={Math.floor(Math.random() * 10000000000000000000000000) + image.id}
              id={image.id + Math.floor(Math.random() * 100000000000000000000)}
              className="mars_photo"
              style={{backgroundImage:`url(${image.img_src.replace("http://", "https://")})`}}
              onClick={() => {this.openGallery(image.id)}}
            >
              </div>

          ))}
        <div className="rover_bottom_nav">

        <div className="rover_bottom_nav_input_c">
        <input type="number" className="rover_bottom_nav_input" id="sol_input" autocomplete="off"/>
        <button className="rover_bottom_nav_input_btn rover_search" onClick={() => {this.changeSol()}}></button>
        </div> 
        <button className="rover_bottom_nav_btn rover_next" onClick={() => {this.nextSol()}}></button>
        <button className="rover_bottom_nav_btn rover_previous" onClick={() => {this.previousSol()}}></button>
        </div>

        </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Rovers));