import React, { Component } from "react";
import Router from "./Router"

class App extends Component {


  componentWillMount(){
    //Create database if doesnt exists
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("CatuxySpaceDatabase", 1);
    open.onupgradeneeded = function() {
        var db = open.result;
        var store = db.createObjectStore("SavedPostsStore", {keyPath: "id"});
        store.createIndex("DateIndex", ["date"]);
    };
    open.onsuccess = function() {
        var db = open.result;
        db.close();
    }

 
  }

  
  render() {
    return (
      <div>
        <Router />
      </div>
);
  }
}

export default App;