import React, { Component } from "react";
import { inject, observer } from "mobx-react";


class SnackBar extends Component {

componentDidMount(){
  var _this = this;
  setTimeout(function(){ 
    if(document.getElementById("snackbar")){
      document.getElementById("snackbar").style.animation = "hideFromRight 0.4s ease-in-out";
    }
    setTimeout(function(){ 
      _this.props.CatuxySpaceStore.disableSnackBar();
     }, 400);
   }, 2500);
}


closeSnackBar(){
  var _this = this;
        document.getElementById("snackbar").style.animation = "hideFromRight 0.4s ease-in-out";
    setTimeout(function(){ 
      _this.props.CatuxySpaceStore.disableSnackBar();
     }, 400);
}

  render() {
    return (
        <div className="snackbar-c" id="snackbar">
        <div className="snackbar-copy"></div>
        <button className="snackbar-message">{this.props.message}</button>
        <div className="snackbar-close" onClick={()=> {this.closeSnackBar()}}></div>
        </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(SnackBar));

