import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import ScrollHide from "./ScrollHide";


//__________________Global function of feed component ________________________________________________________________________________________________

function saveStory(id,date,explanation,hdurl,title,url){
  var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
  var open = indexedDB.open("CatuxySpaceDatabase", 1);

  open.onsuccess = function() {
      var db = open.result;
      var tx = db.transaction("SavedPostsStore", "readwrite");
      var store = tx.objectStore("SavedPostsStore");
      var index = store.index("DateIndex");
      var checkIfSaved = index.get([id]);
      checkIfSaved.onsuccess = function(){
          if(checkIfSaved.result === undefined){
            store.put({
              id: id,
              date: date,
              explanation: explanation,
              hdurl: hdurl,
              title: title,
              url: url
            });
            console.log(`%c Story with id:${id} saved successfully`,'background:#D4EDDA; color:#155724');
          }else{
            console.log("Story already saved " + id)
          }
      }
      tx.oncomplete = function() {
          db.close();
      };
  }
}


function deleteStory(id){
  var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
  var open = indexedDB.open("CatuxySpaceDatabase", 1);

  open.onsuccess = function() {
      var db = open.result;
      var tx = db.transaction("SavedPostsStore", "readwrite");
      var store = tx.objectStore("SavedPostsStore");
      var index = store.index("DateIndex");
      var checkIfSaved = index.get([id]);
      checkIfSaved.onsuccess = function(){
            store.delete(id);
            console.log(`%c Story with id:${id} deleted successfully` ,'background:#E2E3E5; color:#383D41');
      }
      tx.oncomplete = function() {
          db.close();
      };
  }
}

function shareStory(date){
  function constructUrl(date){
    let dateArray = date.split("-");
    let Year = dateArray[0];
    let Month = dateArray[1];
    let Day = dateArray[2];
    return `https://space.catuxy.com/apod/${Year}/${Month}/${Day}`
  }
  var str = constructUrl(date);
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  document.getElementById("snackbar").style.display = "block";
  document.getElementById("snackbar").style.animation = "slideFromRight 0.4s ease-in-out";
  setTimeout(function(){ 
  document.getElementById("snackbar").style.animation = "hideFromRight 0.4s ease-in-out";
  setTimeout(function(){ 
    document.getElementById("snackbar").style.display = "none";
    }, 400);
  }, 1500);
}

//__________________Class ________________________________________________________________________________________________

class Feed extends Component {

//__________________State ________________________________________________________________________________________________

  constructor(props) {
    super(props);
    this.state = {
    feedDate:new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toJSON().slice(0, 10),
    daysToMove: 86400000,
    isFetching: false
    }
    this.infiniteScroll = this.infiniteScroll.bind(this);

  }

  //__________________Component lifecycle ________________________________________________________________________________________________
  componentWillUnmount(){
    document.removeEventListener("scroll", this.infiniteScroll);
  }

  componentWillMount(){
    document.addEventListener("scroll", this.infiniteScroll);
  }



  componentDidMount(){
    var _this = this;
    _this.loadStories();
    function webAppOnline() { 	
      //alert("We are back online all content available");
      _this.props.history.push('/')
    }
    
    function webAppOffline(){
      //alert("The web app is offline some content may not be accessed");
      _this.props.history.push('/saved')
    }
    window.addEventListener('online', webAppOnline); 
    window.addEventListener('offline', webAppOffline);
  }

  

  infiniteScroll(){
    var _this = this;
    var scrollPosition = window.pageYOffset;
    var windowSize = window.innerHeight;
    var bodyHeight = document.body.offsetHeight;
    if(_this.state.isFetching === false && Math.max(bodyHeight - (scrollPosition + windowSize), 0) <= 2000){
      console.log(Math.max(bodyHeight - (scrollPosition + windowSize)));
      _this.loadStories();
    }else{
    }
  }

  createStory(date,explanation,hdurl,title,url,saved){
    var newsfeed = document.getElementById("newsfeed")
    //___________Functions_______________________
    function removeHyphen(date){
      date = date.replace(/-/g, "");
      let intDate = parseInt(date)
      return intDate;
    }
    //___________Processed data variables_________
    var id = removeHyphen(date);
    //___________Story_________
    var story = document.createElement('div');
    //story.setAttribute("class", "story add_transition");
    story.setAttribute("class", "story")
    story.setAttribute("id", id);
    //___________Link of media_________
    var story_hdlink = document.createElement('a');
    story_hdlink.setAttribute("class", "story_hdlink");
    story_hdlink.setAttribute("target", "_blank");
    story_hdlink.setAttribute("href", hdurl);
    story_hdlink.setAttribute("rel", "noopener noreferrer");
    story.appendChild(story_hdlink);
    //___________Media_________
      if(url.indexOf("png") > -1 || url.indexOf("jpg") > -1 || url.indexOf("jpeg") > -1 || url.indexOf("gif") > -1){
        var image = document.createElement('img');
        image.setAttribute("src", url);
        image.setAttribute("alt",title);
        image.setAttribute("class","story_img");
        story_hdlink.appendChild(image);
      }else if(url.indexOf("youtube") > -1){
        var youtube = document.createElement('iframe');
        youtube.setAttribute("width", "100%");
        youtube.setAttribute("height", "315");
        youtube.setAttribute("src", url);
        youtube.setAttribute("title", title);
        youtube.setAttribute("frameborder", "0");
        youtube.setAttribute("allow", "accelerometer; encrypted-media; gyroscope; picture-in-picture");
        youtube.setAttribute("allowfullscreen", "");
        story_hdlink.append(youtube);
      }else if(url.indexOf("vimeo") > -1){
        var vimeo = document.createElement('iframe');
        youtube.setAttribute("width", "100%");
        youtube.setAttribute("height", "315");
        youtube.setAttribute("src", url);
        youtube.setAttribute("title", title);
        youtube.setAttribute("frameborder", "0");
        youtube.setAttribute("allowfullscreen", "");
        youtube.setAttribute("mozallowfullscreen", "");
        youtube.setAttribute("webkitallowfullscreen", "");
        story_hdlink.append(vimeo);
      }else{
        var defaultImage = document.createElement('img');
        defaultImage.setAttribute("src", url);
        defaultImage.setAttribute("alt",title);
        defaultImage.setAttribute("class","story_img");
        story_hdlink.appendChild(defaultImage);
      }  
    //___________Title_________
    var story_title = document.createElement('div');
    story_title.setAttribute("class","story_title");
    var title_text = document.createTextNode(title);
    story_title.appendChild(title_text);
    story.appendChild(story_title);

    //___________Date_________
    var story_date = document.createElement('div');
    story_date.setAttribute("class","story_date");
    var date_text = document.createTextNode(date);
    story_date.appendChild(date_text);
    story.appendChild(story_date);

    //___________Explanation_________
    var story_explanation = document.createElement('div');
    story_explanation.setAttribute("class","story_explanation");
    var explanation_text = document.createTextNode(explanation);
    story_explanation.appendChild(explanation_text);
    story.appendChild(story_explanation);
  
  //___________Story Actions_________
  var story_actions = document.createElement('div');
  story_actions.setAttribute("class","story_actions");
  //___________Story Actions - Share_________
  var story_share = document.createElement('button');
  story_share.setAttribute("class", "s_actions_btn share left");
  story_share.addEventListener("click" , function(){
    shareStory(date)
  })
  story_actions.appendChild(story_share);
  //___________Story Actions - Save_________
  var story_save = document.createElement('button');
  story_save.setAttribute("class","s_actions_btn right");
  story_save.setAttribute("id",id + "save");

  if(saved === true){
    console.log(saved)
    story_save.setAttribute("style","background-image:url('/save-filled.png')");
    story_save.setAttribute("saved", saved)
  }else{
    story_save.setAttribute("style","background-image:url('/save.png')");
    story_save.setAttribute("saved", saved)
  }

  story_save.addEventListener("click" , function(){
    //console.log(id)
  if(document.getElementById(id + "save").getAttribute("saved") === "true"){
    document.getElementById(id + "save").style.backgroundImage = "url('/save.png')";
    deleteStory(id,date,explanation,hdurl,title,url);
    story_save.setAttribute("saved", false)
  }else{
    document.getElementById(id + "save").style.backgroundImage = "url('/save-filled.png')";
    saveStory(id,date,explanation,hdurl,title,url);
    story_save.setAttribute("saved", true)
  }
  })
  story_actions.appendChild(story_save);
  story.appendChild(story_actions);
  if(newsfeed){
    newsfeed.appendChild(story);
    }
  }

  

  checkStory(date,explanation,hdurl,title,url) {
    var _this = this;
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("CatuxySpaceDatabase", 1);
    open.onsuccess = function() {
        var db = open.result;
        var tx = db.transaction("SavedPostsStore", "readwrite");
        var store = tx.objectStore("SavedPostsStore");
        var index = store.index("DateIndex");
        var checkIfSaved = index.get([date]);
        checkIfSaved.onsuccess = function(){
            if(checkIfSaved.result === null || checkIfSaved.result === undefined){
              console.log(`Object with date:${date} was not found in local database`);
              _this.createStory(date,explanation,hdurl,title,url,false);
            }else{
              console.log(`Object with date:${date} is stored in local database`);
              _this.createStory(date,explanation,hdurl,title,url,true);
            }
        }
        checkIfSaved.onerror = function(e) {
          console.dir(e);
        };
        tx.oncomplete = function() {
            db.close();
        };
  }
}


loadStories(){
  let _this = this;

  function getStory(){
  var promise1 = new Promise(function (resolve, reject) {
  _this.setState({isFetching: true});
  fetch(
    `https://api.nasa.gov/planetary/apod?api_key=BbBKQrv5jnrqm2qJ2m9Ba61P9kAPPFYTfDX76o0T&start_date=${_this.state.feedDate}&end_date=${_this.state.feedDate}`
  )
  .then(function (response) {
    if (response.status === 200) {
      return response.json();
    } else {
      console.log(response.status);
      reject("Promise rejected")
    }
  }).then(function(jsonresponse){
    //console.log(JSON.stringify(jsonresponse));
    _this.checkStory(jsonresponse[0].date,jsonresponse[0].explanation,jsonresponse[0].hdurl,jsonresponse[0].title,jsonresponse[0].url);
    console.log("Data obtained sucessfully");
    return jsonresponse;
  }).then(function(jsonresponse){
    var newDate = new Date(new Date().getTime() - _this.state.daysToMove - new Date().getTimezoneOffset() * 60000).toJSON().slice(0, 10);
    _this.setState({feedDate: newDate});
    _this.setState({daysToMove: _this.state.daysToMove + 86400000});
    resolve("Data obtained and date changed");
    return jsonresponse
  })
  .catch(function (error) {
    console.log("fetch_error:", error);
    _this.setState({isFetching: false});
    var newDate = new Date(new Date().getTime() - _this.state.daysToMove - new Date().getTimezoneOffset() * 60000).toJSON().slice(0, 10);
    _this.setState({feedDate: newDate});
    _this.setState({daysToMove: _this.state.daysToMove + 86400000});
    _this.loadStories();
    reject("Promise rejected")
  });
});
return promise1;
}


getStory()
.then(function(response){
  getStory()
.then(function(response){
  getStory()
  .then(function(response){
    getStory()
    .then(function(response){
      getStory()
      .then(function(response){
        console.log("Fetched stories");
        _this.setState({isFetching: false});
        return response;
      }).catch(function (error) {
        console.log("promise-error", error);
      });
      return response;
    }).catch(function (error) {
      console.log("promise-error", error);
    });
    return response;
  }).catch(function (error) {
    console.log("promise-error", error);
  });
  return response;
}).catch(function (error) {
  console.log("promise-error", error);
});
return response;
}).catch(function (error) {
  console.log("promise-error", error);
});
}


//_____________________Snack bar______________________________

closeSnackBar(){
        document.getElementById("snackbar").style.animation = "hideFromRight 0.4s ease-in-out";
    setTimeout(function(){ 
      document.getElementById("snackbar").style.display = "none";
     }, 400);
}



  render() {
    return (
      <div>
        <TopNav tabname="News Feed" showSave={true} />
        <div className="add_transition">
         <div className="feed-c" id="newsfeed"></div>
        <div id="loading"  style={{ display: this.state.isFetching === false? 'none': 'block'}}></div>
        
        <div className="sp sp-hydrogen"></div>

        </div>
        <div className="snackbar-c" id="snackbar" style={{"display": "none"}}>
        <div className="snackbar-copy"></div>
        <button className="snackbar-message">Link copied to clipboard</button>
        <div className="snackbar-close" onClick={()=> {this.closeSnackBar()}}></div>
        </div>
        <ScrollHide />
        <BottomNav current={"newsfeed"}/>
      </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Feed));


