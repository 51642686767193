import React, { Component } from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";

function localData(){
  if(localStorage.getItem("api_key") === null) {
    localStorage.setItem("api_key", "DEMO_KEY");
    return "DEMO_KEY";
  }else{
    return localStorage.getItem("api_key");
  }
}

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: localData(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveKey = this.saveKey.bind(this);
  }

  handleChange(event) {
    this.setState({key: event.target.value}); //event.target.value refers to the value of the input 
  }
  
  saveKey(){
    localStorage.setItem("api_key", this.state.key);
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <TopNav tabname="API Key" />
        <div className="add_transition">
        <div className="key-outer-c">
        <div className="key-inner-c">

        <div className="key-item">
        <div className="key-instructions">Insert your key down below</div>
        <input type="text" value={this.state.key} onChange={this.handleChange} className="key-input" />
        <button onClick={this.saveKey} className="key-button">Save</button>
        </div>

        <div className="key-item">
        <div className="key-instructions">Current key</div>
        <div className="key-saved">{localStorage.getItem("api_key")}</div>
        </div>
        </div>
        </div>
        </div>
        <BottomNav current={"key"}/>
      </div>
    );
  }
}

export default About;
