import React, { Component } from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import HowToUseRovers from "../img/how_to_use_rovers.png"
class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
    rovers_height: "40px",
    }
  }

  changeRoversHeight(){
    var _this = this;
      if(_this.state.rovers_height === "auto"){
        _this.setState({rovers_height: "40px"});
      }else{
        _this.setState({rovers_height: "auto"});
    }
  }

  render() {
    return (
      <div>
        <TopNav tabname="About" />
        <div className="add_transition">
        <div className="about-c">
        <div className="catuxy-space-logo"></div>

        <div className="expandable_about" style={{height: this.state.rovers_height}}>
        <button className="expandable_button" onClick={() => {this.changeRoversHeight()}}></button>
        <button className="expandable_title" onClick={() => {this.changeRoversHeight()}}>How to navigate through the mars rovers photos</button>

        <div className="expandable_section_title">Main concepts</div>
        <img alt="" src={HowToUseRovers} className="expandable_photo"/>

        </div>


        </div>
        </div>

        <BottomNav current={"about"}/>
      </div>
    );
  }
}

export default About;
