import React, { Component } from "react";
import {Link} from 'react-router-dom';

class TopBarReturn extends Component {



  render() {
    return (
      <div className="top_bar_return">
       <Link to="/explore" exact="true"><button className="top_bar_return_btn"></button></Link>
        <button className="top_bar_return_title">{this.props.title}</button>
      </div>
    );
  }
}

export default TopBarReturn;