import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.nav_height = this.nav_height.bind(this);
  }
  nav_height(){
    return this.props.CatuxySpaceStore.nav_height_show;
  }

  showSaveButton(){
    if(this.props.showSave === true){
        return <Link to="/saved" exact="true"><button className="tab-save"></button></Link>
    }else{
        return "";
    }
  }

  opacity(){
    if(this.nav_height() === "0px"){
      return 0;
    }else{
      return 1;
    }
  }

  render() {
       return (
        <div className="tab-c" style={{height:this.nav_height(), opacity:this.opacity()}}>
        <button className="tab-name">{this.props.tabname}</button>
        {this.showSaveButton()}
        </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(TopNav));
