import React, { Component } from "react";
import { inject, observer } from "mobx-react";

var lastScrollTop = 0;

class ScrollHide extends Component {
  constructor(props) {
    super(props);
    this.detectScroll = this.detectScroll.bind(this);
  }
  detectScroll(){
    var _this = this;
    var st = window.pageYOffset || document.documentElement.scrollTop; 
    if (st > lastScrollTop){
       // downscroll code 
       _this.props.CatuxySpaceStore.scrollState(false)
    } else {
       // upscroll code
       _this.props.CatuxySpaceStore.scrollState(true)
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }
  componentDidMount(){
    document.addEventListener("scroll",this.detectScroll, false);
  }
  componentWillUnmount(){
  document.removeEventListener("scroll",this.detectScroll, false);
  } 

  render() {
    return (
      <div name="Hey I'm scroll hide :)">

      </div>
    );
  }
}
export default inject("CatuxySpaceStore")(observer(ScrollHide));
