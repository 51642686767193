import {
  observable,
  action,
  computed,
  decorate
} from "mobx";

class CatuxySpaceStore {
 
  savedPosts = [];
  api_key = localStorage.getItem("api_key");
  nav_height = "60px"
  snackBarState = false;
  performingRequests = false;

  scrollState = (state) => {
    if (state === true) {
      this.nav_height = "60px";
      console.log("show");
    } else {
      this.nav_height = "0px";
      console.log("hide");
    }
  };


  enableSnackBar = () => {
    this.snackBarState = true;
  };
  disableSnackBar = () => {
    this.snackBarState = false;
  };

  getSavedPosts = () => {
    var _this = this;
    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    var open = indexedDB.open("CatuxySpaceDatabase", 1);
    open.onsuccess = function() {
    
        var db = open.result;
        var tx = db.transaction("SavedPostsStore", "readwrite");
        var store = tx.objectStore("SavedPostsStore");
        var request = store.getAll();
    
        request.onsuccess = function(){
            _this.savedPosts = request.result.reverse()
        }
        tx.oncomplete = function() {
            db.close();
        };
    }
    }

//__________Feed- Computed properties ______________________

  //Computed property that return string that changes nav bar height
  get nav_height_show() {
    return this.nav_height;
  }

  //Snack bar state computed property to show or hide the component
  get showSnackBarState() {
    return this.snackBarState;
  }

  get showSavedPosts() {
    return this.savedPosts;
  }
}

decorate(CatuxySpaceStore, {
  nav: action,
  api_key: observable,
  nav_height:observable,
  nav_height_show:computed,
  scrollState:action,
  snackBarState: observable,
  showSnackBarState: computed,
  enableSnackBar:action,
  disableSnackBar:action,
  getSavedPosts:action,
  showSavedPosts:computed,
  savedPosts:observable
});
const store = new CatuxySpaceStore();
export default store;