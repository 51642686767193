import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TopBarReturn from './TopBarReturn';
import Rovers from './Rovers';

class Curiosity extends Component {

componentDidMount(){

}

  render() {
    return (
        <div>
          <TopBarReturn title="Curiosity"/>
          <Rovers name="curiosity" sol={this.props.match.params.sol} routerData={this.props} activateKeys={true}/>
        </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Curiosity));