import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import TopBarReturn from './TopBarReturn';
import Rovers from './Rovers';

class Opportunity extends Component {

componentDidMount(){

}

  render() {
    return (
        <div>
          <TopBarReturn title="Opportunity"/>
          <Rovers name="opportunity" sol={this.props.match.params.sol} routerData={this.props} activateKeys={true}/>
        </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Opportunity));