import React, { Component } from "react";
import {NavLink} from 'react-router-dom';
import { inject, observer } from "mobx-react";

class BottomNav extends Component {
  nav_height(){
    return this.props.CatuxySpaceStore.nav_height_show;
  }

  opacity(){
    if(this.nav_height() === "0px"){
      return 0;
    }else{
      return 1;
    }
  }

  
  render() {
   //text-active
    //const { CatuxySpaceStore } = this.props;
    return (
      <div className="bottom-nav" style={{height:this.nav_height() , opacity:this.opacity()}} >
<div className="bottom-nav-inside">

  <NavLink to="/" exact={true}>
  <div className="nav-item">
  <div className={this.props.current==="newsfeed" ? 'nav-item-img feed-active' : 'nav-item-img feed'} ></div>
  <div className={this.props.current==="newsfeed" ? 'nav-item-title text-active' : 'nav-item-title'}>Feed</div>
  </div>
  </NavLink>
      
  <NavLink to="/about" exact={true} activeClassName='about-active'>
  <div className="nav-item">
  <div className={this.props.current==="about" ? 'nav-item-img about-active' : 'nav-item-img about'}></div>
  <div className={this.props.current==="about" ? 'nav-item-title text-active' : 'nav-item-title'}>About</div>
  </div>
  </NavLink>  
  
  <NavLink to="/key" exact={true} activeClassName='key-active'>
  <div className="nav-item">
  <div className={this.props.current==="key" ? 'nav-item-img key-active' : 'nav-item-img key'}></div>
  <div className={this.props.current==="key" ? 'nav-item-title text-active' : 'nav-item-title'}>Key</div>
  </div>
  </NavLink>

  <NavLink to="/explore" exact={true} activeClassName='explore-active'>
  <div className="nav-item">
  <div className={this.props.current==="explore" ? 'nav-item-img explore-active' : 'nav-item-img explore'}></div>
  <div className={this.props.current==="explore" ? 'nav-item-title text-active' : 'nav-item-title'}>Explore</div>
  </div>
  </NavLink>
  </div>
      </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(BottomNav));
