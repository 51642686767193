import React, { Component } from "react";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import { inject, observer } from "mobx-react";
import ScrollHide from "./ScrollHide"

class Post extends Component {

    constructor(props) {
        super(props);
        this.state = {
        PostTitle: "",
        PostExplanation: "",
        PostDate:"",
        PostUrl:"",
        }
      }


      componentWillMount(){
        this.getPost(this.props.match.params.year,this.props.match.params.month,this.props.match.params.day);
      }

    getPost = (year,month,day) => {
        let _this = this;

        function getPostData(year,month,day) {
          var promise1 = new Promise(function (resolve, reject) {
            fetch(
                `https://api.nasa.gov/planetary/apod?api_key=BbBKQrv5jnrqm2qJ2m9Ba61P9kAPPFYTfDX76o0T&date=${year}-${month}-${day}`
              )
              .then(function (response) {
                if (response.status === 200) {
                  var jsonresponse = response.json();
                  console.log(response.status);
                  resolve(jsonresponse);
                } else {
                  reject(`The fetch request failed with a status of: ${response.status}`);
                }
              })
              .catch(function (error) {
                console.log("fetch_error:", error);
                reject("The fetch request failed");
              });
          });
          return promise1;
        }
    
    getPostData(year,month,day).then(function(response){
        _this.setState({PostTitle: response.title});
        _this.setState({PostDate: response.date});
        _this.setState({PostUrl: response.url});
        _this.setState({PostExplanation: response.explanation});
        return "Post loaded"
    }).then(function(status){
        console.log(status)
    })
    
    
      }
      defineGraphic_element(posturl,posttitle){
        if(posturl.indexOf("png") > -1 || posturl.indexOf("jpg") > -1 || posturl.indexOf("jpeg") > -1 || posturl.indexOf("gif") > -1 ){
          return  <img src={posturl} alt={posttitle} className="ind-post-img" />;
      }else if(posturl.indexOf("youtube") > -1){
          return <iframe width="100%" height="315" src={posturl} title={posttitle} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      }else if(posturl.indexOf("vimeo") > -1){
          return <iframe src={posturl} title={posttitle} width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
      }else{
        return <img src={posturl} alt={posttitle} className="post-img" />;
      }
      }
    
      
        
  render() {
    return (
      <div>
        <TopNav tabname="NASA APOD"/>
        <div className="ind-post-outer-c">
        <div className="ind-post-inner-c">
            {this.defineGraphic_element(this.state.PostUrl,this.state.postTitle)}
            <div className="ind-post-title">{this.state.PostTitle}</div>
            <div className="ind-post-date">{this.state.PostDate}</div> 
            <div className="ind-post-explanation">{this.state.PostExplanation}</div>
          </div>
          </div>
        <ScrollHide />
        <BottomNav />
      </div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Post));
