import React, { Component } from "react";
import {Switch, Route} from "react-router-dom";
//Routes
import Feed from "./components/Feed";
import About from "./components/About";
import ApiKey from "./components/ApiKey";
import Explore from "./components/Explore";
import Post from "./components/Post";
import Saved from "./components/Saved";
import Curiosity from "./components/Curiosity";
import Opportunity from "./components/Opportunity";
import Spirit from "./components/Spirit";

class Router extends Component {
  render() {
    return (
        <Switch> 
        <Route path="/" exact component={Feed} />
        <Route path="/about" exact component={About} />
        <Route path="/key" exact component={ApiKey} />
        <Route path="/explore" exact component={Explore} />
        <Route path="/apod/:year/:month/:day" exact component={Post} />
        <Route path="/saved" exact component={Saved} />
        <Route path="/explore/curiosity/:sol" exact component={Curiosity} />
        <Route path="/explore/opportunity/:sol" exact component={Opportunity} />
        <Route path="/explore/spirit/:sol" exact component={Spirit} />

      </Switch>
    );
  }
}

export default Router;
