import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";

class Explore extends Component {
  render() {
    //const { CatuxySpaceStore } = this.props;
    return (
<div className="">
<TopNav tabname="Explore" />

<div className="add_transition">
<div className="explore-c">
<div className="explore-inner-c">
<Link to="explore/curiosity/0" exact="true">
    <div className="explore-item curiosity">
    <div className="explore-item-title">Curiosity rover photos</div>
    </div>
  </Link>
      
  <Link to="explore/opportunity/1" exact="true">
    <div className="explore-item opportunity">
    <div className="explore-item-title">Opportunity rover photos</div>
    </div>
  </Link>  
  
  <Link to="explore/spirit/1" exact="true">
  <div className="explore-item spirit">
  <div className="explore-item-title">Spirit rover photos</div>
  </div>
  </Link>

  <Link to="explore/hubble" exact="true">
  <div className="explore-item hubble">
  <div className="explore-item-title">Hubble Space Telescope photos</div>
  </div>
  </Link>


</div>
</div>
</div>
<BottomNav current={"explore"}/>
</div>
    );
  }
}

export default inject("CatuxySpaceStore")(observer(Explore));
